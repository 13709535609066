import { useState } from "react";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import SideNavigation from "../components/Layout/SideNavigation";
import TopNavigation from "../components/Layout/TopNavigation";
import ResetPasswordModal from "../components/Overlays/ResetPasswordModal";
import { AccountSettings } from "./AccountSettings";
import { OrgAccounts } from "./OrgAccounts";
import PurchaseReportBooks from "./PurchaseReportBooks/PurchaseReportBooks";
import PurchaseReports from "./PurchaseReports/PurchaseReports";
import { ReporterAccounts } from "./ReporterAccounts";
import ReportingEntities from "./ReportingEntities/ReportingEntities";

function Home() {
  // Page Data
  const [purchaseReportData, setPurchaseReportData] = useState<any>({});
  const [reportingEntityData, setReportingEntityData] = useState<any>({});
  const [purchaseReportBook, setPurchaseReportBook] = useState<any>({});
  const [reporterAccountsData, setReporterAccountsData] = useState<any>({});
  const [orgAccountsData, setOrgAccountsData] = useState<any>({});

  return (
    <main className="h-100 d-flex flex-column">
      <ResetPasswordModal />
      <TopNavigation />
      <div className="d-flex flex-grow-1 bg-light">
        <SideNavigation
          setPurchaseReportData={setPurchaseReportData}
          setReportingEntityData={setReportingEntityData}
          setPurchaseReportBook={setPurchaseReportBook}
          setReporterAccountsData={setReporterAccountsData}
        />
        <div className="flex-grow-1 mx-4 mt-5">
          <div className="container-xl pb-4">
            <Outlet />
            <Routes>
              {/* DATA ENTRY */}
              <Route
                path="purchase-reports/*"
                element={
                  <PurchaseReports
                    purchaseReportData={purchaseReportData}
                    setPurchaseReportData={setPurchaseReportData}
                  />
                }
              />
              <Route
                path="reporting-entities/*"
                element={
                  <ReportingEntities
                    reportingEntityData={reportingEntityData}
                    setReportingEntityData={setReportingEntityData}
                  />
                }
              />
              <Route
                path="purchase-report-books/*"
                element={
                  <PurchaseReportBooks
                    purchaseReportBook={purchaseReportBook}
                    setPurchaseReportBook={setPurchaseReportBook}
                  />
                }
              />
              {/* REPORTING */}
              {/* DATA LISTS */}
              {/* APPLICATION */}
              <Route
                path="reporter-accounts/*"
                element={
                  <ReporterAccounts
                    reporterAccountsData={reporterAccountsData}
                    setReporterAccountsData={setReporterAccountsData}
                  />
                }
              />
              <Route
                path="org-accounts/*"
                element={
                  <OrgAccounts
                    orgAccountsData={orgAccountsData}
                    setOrgAccountsData={setOrgAccountsData}
                  />
                }
              />

              {/* PROFILE */}
              <Route path="account-settings" element={<AccountSettings />} />

              {/* Catch all routes NOT defined and route back to the main page */}
              <Route path="/*" element={<Navigate to="/purchase-reports" />} />
            </Routes>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
