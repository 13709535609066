import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { LastUpdatedLabel } from "../../components";
import { PasswordReset, UserInformation } from "../../components/Accounts";
import AccountRoleControl from "../../components/Accounts/AccountRoleControl";
import AccountStatus from "../../components/Accounts/AccountStatus";
import { Account } from "../../models";
import { AccountRole } from "../../models/Account";
import { getAccountId } from "../../services/ApiService";
import { assignRole, removeRole, useAccountQuery } from "../queries";

function OrgAccountDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { account, newAccount } = location.state as {
    account: Account;
    newAccount?: boolean;
  };

  const { data: orgAccountData } = useAccountQuery(account.id);
  const [orgAccount, setOrgAccount] = useState<Account>();

  useEffect(() => {
    if (!orgAccountData) return;
    setOrgAccount(orgAccountData);
  }, [orgAccountData]);

  const handleCancel = () => {
    if (newAccount) {
      navigate("/org-accounts");
    } else {
      navigate(-1);
    }
  };

  const handleAssignRole = async (role: AccountRole) => {
    if (!orgAccount?.id || !role) return;
    return assignRole(role.name, orgAccount.id);
  };

  const handleRemoveRole = (role: AccountRole) => {
    if (!orgAccount?.id || !role) return;
    return removeRole(role.name, orgAccount.id);
  };

  return (
    <>
      <UserInformation accountData={orgAccount} />

      <AccountRoleControl
        options={orgAccount?.accountAccountRole}
        isSelf={getAccountId() === orgAccount?.id}
        handleAssignRole={handleAssignRole}
        handleRemoveRole={handleRemoveRole}
      />

      <PasswordReset accountData={orgAccount} setAccountData={setOrgAccount} />

      <AccountStatus
        accountId={account.id}
        accountData={orgAccount}
        setAccountData={setOrgAccount}
      />

      <Row className="mb-5">
        <Col xs={6}>
          <Button variant="outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col xs={6}>
          <LastUpdatedLabel
            account={account.editor}
            timeStamp={account.updatedAt}
            label="Updated:"
          />
          <LastUpdatedLabel
            account={account.creator}
            timeStamp={account.createdAt}
            label="Created:"
          />
        </Col>
      </Row>
    </>
  );
}

export default OrgAccountDetails;
