import { useState } from "react";
import {
  ArrowBarLeft,
  ArrowBarRight,
  BoxArrowUpRight,
  Clipboard2Data,
  DatabaseAdd,
  Gear,
  ListColumns,
  Person
} from "react-bootstrap-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../utils/logout";
import PermissionsGate from "../Utils/PermissionsGate";

const { REACT_APP_METABASE_APP_URL } = process.env;

type SideNavigationProps = {
  setPurchaseReportData: React.Dispatch<any>;
  setReportingEntityData: React.Dispatch<any>;
  setPurchaseReportBook: React.Dispatch<any>;
  setReporterAccountsData: React.Dispatch<any>;
};

type CustomNavLinkProps = {
  label?: string;
  to: string;
  target?: string;
  className?: string;
  externalLink?: boolean;
};

function CustomNavLink({
  label,
  to,
  target,
  externalLink,
  className
}: CustomNavLinkProps) {
  return (
    <NavLink
      to={to}
      target={target || "_self"}
      rel="noopener noreferrer"
      type="button"
      className={({ isActive }) => (isActive ? "nav-active " : "") + className}
    >
      {label}
      {externalLink && <BoxArrowUpRight className="nav-external-icon ms-2" />}
    </NavLink>
  );
}

export default function SideNavigation({
  setPurchaseReportData,
  setReportingEntityData,
  setPurchaseReportBook,
  setReporterAccountsData
}: SideNavigationProps) {
  const [collapseSideNav, setCollapseSideNav] = useState(false);

  const [showDataEntryOverlay, setShowDataEntryOverlay] = useState(false);
  const [showReportingOverlay, setShowReportingOverlay] = useState(false);
  const [showDataListOverlay, setShowDataListOverlay] = useState(false);
  const [showApplicationOverlay, setShowApplicationOverlay] = useState(false);
  const [showProfileOverlay, setShowProfileOverlay] = useState(false);

  const clearAllToast = () => {
    toast.dismiss();
  };

  return (
    <nav
      id="side-navigation-menu"
      className={`${
        collapseSideNav && "collapse-side-nav"
      } d-flex flex-column justify-content-between p-4 bg-white shadow h-100`}
    >
      <ul
        onClick={clearAllToast}
        id="side-navigation-list"
        className="list-unstyled ps-0"
      >
        {/* Data Entry */}
        <PermissionsGate resource="menu-dataEntry">
          <li
            className="nav-menu-list"
            onMouseEnter={() => setShowDataEntryOverlay(true)}
            onMouseLeave={() => setShowDataEntryOverlay(false)}
          >
            <strong className="nav-header">
              <OverlayTrigger
                show={collapseSideNav && showDataEntryOverlay}
                placement="right"
                overlay={
                  <Popover className="side-navigation-popover">
                    <Popover.Header className="bg-light">
                      Data Entry
                    </Popover.Header>
                    <Popover.Body>
                      <ul>
                        <li
                          onClick={() => {
                            setPurchaseReportData({});
                          }}
                        >
                          <CustomNavLink
                            to="purchase-reports"
                            label="Purchase Reports"
                          />
                        </li>
                        <li
                          onClick={() => {
                            setReportingEntityData({});
                          }}
                        >
                          <CustomNavLink
                            to="reporting-entities"
                            label="Reporters"
                          />
                        </li>
                        <li
                          onClick={() => {
                            setPurchaseReportBook({});
                          }}
                        >
                          <CustomNavLink
                            to="purchase-report-books"
                            label="Purchase Report Books"
                          />
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <DatabaseAdd />
              </OverlayTrigger>
              <span className="nav-menu-title">Data Entry</span>
            </strong>
            <ul>
              <li
                onClick={() => {
                  setPurchaseReportData({});
                }}
              >
                <CustomNavLink to="purchase-reports" label="Purchase Reports" />
              </li>
              <li
                onClick={() => {
                  setReportingEntityData({});
                }}
              >
                <CustomNavLink to="reporting-entities" label="Reporters" />
              </li>
              <li
                onClick={() => {
                  setPurchaseReportBook({});
                }}
              >
                <CustomNavLink
                  to="purchase-report-books"
                  label="Purchase Report Books"
                />
              </li>
            </ul>
          </li>
        </PermissionsGate>
        {/* Reporting */}
        <PermissionsGate resource="menu-reporting">
          <li
            className="nav-menu-list"
            onMouseEnter={() => setShowReportingOverlay(true)}
            onMouseLeave={() => setShowReportingOverlay(false)}
          >
            <strong className="nav-header">
              <OverlayTrigger
                show={collapseSideNav && showReportingOverlay}
                placement="right"
                overlay={
                  <Popover className="side-navigation-popover">
                    <Popover.Header className="bg-light">
                      Reporting
                    </Popover.Header>
                    <Popover.Body>
                      <ul>
                        <li>
                          <CustomNavLink
                            to={REACT_APP_METABASE_APP_URL!}
                            label="Metabase"
                            target="_blank"
                            externalLink
                          />
                        </li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Clipboard2Data />
              </OverlayTrigger>
              <span className="nav-menu-title">Reporting</span>
            </strong>
            <ul>
              <li>
                <CustomNavLink
                  to={REACT_APP_METABASE_APP_URL!}
                  label="Metabase"
                  target="_blank"
                  externalLink
                />
              </li>
            </ul>
          </li>
        </PermissionsGate>
        {/* Data Lists */}
        <PermissionsGate resource="menu-dataList">
          <li
            className="nav-menu-list"
            onMouseEnter={() => setShowDataListOverlay(true)}
            onMouseLeave={() => setShowDataListOverlay(false)}
          >
            <strong className="nav-header">
              <OverlayTrigger
                show={collapseSideNav && showDataListOverlay}
                placement="right"
                overlay={
                  <Popover className="side-navigation-popover">
                    <Popover.Header className="bg-light">
                      Data Lists
                    </Popover.Header>
                    <Popover.Body>
                      <ul>
                        <li>Application Fields</li>
                        <li>Ports</li>
                        <li>Species</li>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <ListColumns />
              </OverlayTrigger>
              <span className="nav-menu-title">Data Lists</span>
            </strong>
            <ul>
              <li>Application Fields</li>
              <li>Ports</li>
              <li>Species</li>
            </ul>
          </li>
        </PermissionsGate>
        {/* Application */}
        <PermissionsGate resource="menu-application">
          <li
            className="nav-menu-list"
            onMouseEnter={() => setShowApplicationOverlay(true)}
            onMouseLeave={() => setShowApplicationOverlay(false)}
          >
            <strong className="nav-header">
              <OverlayTrigger
                show={collapseSideNav && showApplicationOverlay}
                placement="right"
                overlay={
                  <Popover className="side-navigation-popover">
                    <Popover.Header className="bg-light">
                      Application
                    </Popover.Header>
                    <Popover.Body>
                      <ul>
                        <li>
                          <CustomNavLink
                            to="reporter-accounts"
                            label="Reporter Accounts"
                          />
                        </li>
                        <PermissionsGate resource="menu-org-accounts">
                          <li>
                            <CustomNavLink
                              to="org-accounts"
                              label="Org Accounts"
                            />
                          </li>
                        </PermissionsGate>
                        <PermissionsGate resource="menu-dev">
                          <>
                            <li>Settings</li>
                          </>
                        </PermissionsGate>
                      </ul>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Gear />
              </OverlayTrigger>
              <span className="nav-menu-title">Application</span>
            </strong>
            <ul>
              <li>
                <CustomNavLink
                  to="reporter-accounts"
                  label="Reporter Accounts"
                />
              </li>
              <PermissionsGate resource="menu-org-accounts">
                <li>
                  <CustomNavLink to="org-accounts" label="Org Accounts" />
                </li>
              </PermissionsGate>
              <PermissionsGate resource="menu-dev">
                <>
                  <li>Settings</li>
                </>
              </PermissionsGate>
            </ul>
          </li>
        </PermissionsGate>
        {/* Profile */}
        <li
          className="nav-menu-list"
          onMouseEnter={() => setShowProfileOverlay(true)}
          onMouseLeave={() => setShowProfileOverlay(false)}
        >
          <strong className="nav-header">
            <OverlayTrigger
              show={collapseSideNav && showProfileOverlay}
              placement="right"
              overlay={
                <Popover className="side-navigation-popover">
                  <Popover.Header className="bg-light">Profile</Popover.Header>
                  <Popover.Body>
                    <ul>
                      <li>
                        <CustomNavLink
                          to="account-settings"
                          label="Account Settings"
                        />
                      </li>
                      <PermissionsGate resource="menu-dev">
                        <>
                          <li>User Guide</li>
                        </>
                      </PermissionsGate>
                      <li onClick={logout}>
                        <span className="side-nav-link">Sign Out</span>
                      </li>
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <Person />
            </OverlayTrigger>
            <span className="nav-menu-title">Profile</span>
          </strong>
          <ul>
            <li>
              <CustomNavLink to="account-settings" label="Account Settings" />
            </li>
            <PermissionsGate resource="menu-dev">
              <>
                <li>User Guide</li>
              </>
            </PermissionsGate>
            <li onClick={logout}>
              <span className="side-nav-link">Sign Out</span>
            </li>
          </ul>
        </li>
      </ul>

      <div className="w-100 py-3 border-top text-center">
        <button
          className={`${!collapseSideNav && "w-100"} btn btn-outline-dark`}
          onClick={() => setCollapseSideNav(!collapseSideNav)}
        >
          {collapseSideNav ? (
            <ArrowBarRight />
          ) : (
            <>
              <ArrowBarLeft className="me-2" />
              Collapse
            </>
          )}
        </button>
      </div>
    </nav>
  );
}
