import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import { setAccountPassword } from "../../pages/queries";
import { getAccountId } from "../../services/ApiService";
import passwordConfirmationValidationSchema from "../../utils/validation/passwordConfirmationValidationSchema";
import passwordValidationSchema from "../../utils/validation/passwordValidationSchema";
import ListAPIErrors, { APIError } from "../Error/ListAPIErrors";

function ResetPasswordModal() {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [changePasswordSuccessfully, setChangePasswordSuccessfully] =
    useState(false);
  const [APIErrors, setAPIErrors] = useState<APIError[]>([]);
  const validationSchema = yup.object().shape({
    password: passwordValidationSchema,
    confirmPassword: passwordConfirmationValidationSchema
  });

  useEffect(() => {
    const status = window.localStorage.getItem("status");
    if (status === "Reset Password") {
      setShow(true);
      setDisabled(false);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    window.localStorage.removeItem("status");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Reset your password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your account requires a password reset. Please create a new password
            below.
          </p>
          <Formik
            validationSchema={validationSchema}
            onSubmit={async values => {
              setAPIErrors([]);
              setDisabled(true);
              try {
                const accountId = getAccountId();
                if (!accountId) return;
                await setAccountPassword(
                  accountId,
                  values.password,
                  false
                ).then(() => {
                  setChangePasswordSuccessfully(true);
                  setTimeout(() => {
                    handleClose();
                  }, 2000);
                });
              } catch (err: any) {
                setAPIErrors(err.errors);
                setDisabled(false);
                console.error("Registration failed:", err);
              }
            }}
            initialValues={{
              password: "",
              confirmPassword: ""
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form onSubmit={handleSubmit} className="mb-4">
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password && touched.password}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    isInvalid={
                      !!errors.confirmPassword && touched.confirmPassword
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>

                <Alert variant="warning" className="mb-4">
                  Passwords must have at least 8 characters and contain the
                  following: uppercase letters, lowercase letters, numbers, and
                  symbols.
                </Alert>

                {changePasswordSuccessfully && (
                  <Alert variant="success" className="mb-4">
                    Password changed, thank you!
                  </Alert>
                )}

                <div className="d-flex align-items-end">
                  <Button
                    variant="success"
                    className="me-4"
                    type="submit"
                    disabled={disabled}
                  >
                    Save Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <ListAPIErrors errors={APIErrors} setErrors={setAPIErrors} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResetPasswordModal;
