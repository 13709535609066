import { Fragment } from "react";

import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import React from "react";
import {
  Table as BTable,
  Badge,
  Button,
  ButtonGroup,
  Form
} from "react-bootstrap";
import { ArrowDown, ArrowDownUp, ArrowUp } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Account } from "../../models";
import { AccountRole } from "../../models/Account";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TableProps<TData> = {
  data: Account[];
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
};

function OrgAccountsTable({
  data = [],
  globalFilter,
  setGlobalFilter
}: TableProps<any>): JSX.Element {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({ itemRank });
    return itemRank.passed;
  };

  const columns: ColumnDef<Account>[] = [
    {
      accessorFn: (row: { name: string }) => row.name,
      id: "name",
      header: "Name",
      cell: (info: any) => (
        <Link to="edit" state={{ account: info.row.original }}>
          {info.getValue()}
        </Link>
      ),
      footer: (props: any) => props.column.id,
      filterFn: "fuzzy" as any
    },
    {
      accessorFn: row => row.accountAccountRole,
      id: "roles",
      header: "Roles",
      cell: (info: any) => (
        <>
          {info.getValue().map((role: { accountRole: AccountRole }) => (
            <Badge
              pill
              className={`roll-bg-${role.accountRole.id} bg-secondary me-1`}
              key={role.accountRole.id}
            >
              {role.accountRole.name}
            </Badge>
          ))}
        </>
      ),
      footer: (props: any) => props.column.id,
      filterFn: "fuzzy" as any
    },
    {
      accessorKey: "loginId",
      id: "loginId",
      header: "Username",
      footer: props => props.column.id,
      filterFn: "fuzzy" as any
    },
    {
      accessorKey: "email",
      id: "email",
      header: "Email",
      footer: props => props.column.id,
      filterFn: "fuzzy" as any
    },
    {
      accessorKey: "updatedAt",
      id: "updatedAt",
      header: "Last Updated",
      footer: props => props.column.id,
      filterFn: "fuzzy" as any
    },
    {
      accessorFn: row => row.status.name,
      id: "status",
      header: "Status",
      cell: (info: any) => (
        <span
          className={`status-dot status-dot-${String(info.getValue())
            .split(" ")
            .join("-")
            .toLowerCase()}`}
        >
          {info.getValue()}
        </span>
      ),
      footer: props => props.column.id
    }
  ];

  const table = useReactTable<any>({
    data,
    columns,
    state: {
      pagination,
      globalFilter
    },
    filterFns: {
      fuzzy: fuzzyFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "fuzzy" as any
  });

  return (
    <div>
      <BTable responsive className="border">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    className="bg-body-secondary"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <span className="me-2">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </span>
                        {header.column.getCanSort() && (
                          <>
                            {{
                              asc: <ArrowUp />,
                              desc: <ArrowDown />
                            }[header.column.getIsSorted() as string] ?? (
                              <ArrowDownUp />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Fragment key={row.id}>
                <tr className="border-top">
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </BTable>

      <div className="d-flex justify-content-between">
        <div>
          <ButtonGroup>
            <Button
              size="sm"
              onClick={() => table.firstPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </Button>
            <Button
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </Button>
            <Button
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </Button>
            <Button
              size="sm"
              onClick={() => table.lastPage()}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </Button>
          </ButtonGroup>
        </div>

        <div>
          <span className="me-1">Page:</span>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount().toLocaleString()}
          </strong>
        </div>

        <Form.Select
          className="d-inline"
          style={{ width: "10rem" }}
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Form.Select>
      </div>
      <div>
        Showing {table.getRowModel().rows.length.toLocaleString()} of{" "}
        {table.getRowCount().toLocaleString()} Organization Accounts
      </div>
    </div>
  );
}

export default OrgAccountsTable;
