import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { Account } from "../../models";
import { resetPasswordByEmail, setAccountPassword } from "../../pages/queries";
import { generatePassword } from "../../utils";
import passwordValidationSchema from "../../utils/validation/passwordValidationSchema";

export type PasswordResetProps = {
  accountData: Account | undefined;
  setAccountData?: React.Dispatch<React.SetStateAction<Account | undefined>>;
  showEmailPasswordResetButton?: boolean;
  showRequirePasswordChange?: boolean;
};

function PasswordReset({
  accountData,
  setAccountData,
  showEmailPasswordResetButton = true,
  showRequirePasswordChange = true
}: PasswordResetProps) {
  const [account, setAccount] = useState<Account>();
  const [requirePasswordReset, setRequirePasswordReset] = useState(
    showRequirePasswordChange
  );

  const passwordSchema = yup.object().shape({
    password: passwordValidationSchema
  });

  useEffect(() => {
    if (!accountData) return;
    setAccount(accountData);
  }, [accountData]);

  const handleEmailPasswordResetLink = async () => {
    if (!account?.loginId) return;
    await resetPasswordByEmail(account?.loginId);
  };

  const handlePasswordChange = async (values: { password: string }) => {
    if (!account?.id) return;
    const response = await setAccountPassword(
      account.id,
      values.password,
      requirePasswordReset
    );

    if (setAccountData) {
      setAccountData(response);
    }
  };

  return (
    <Card>
      <Card.Body>
        {showEmailPasswordResetButton && (
          <>
            <Card.Title className="mb-3">Send Password Reset Link</Card.Title>
            <div className="mb-5">
              <Button
                variant="outline-success"
                onClick={handleEmailPasswordResetLink}
              >
                Email Password Reset Link
              </Button>
            </div>
          </>
        )}
        <Formik
          validationSchema={passwordSchema}
          onSubmit={values => handlePasswordChange(values)}
          enableReinitialize
          initialValues={{
            password: ""
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <Card.Title className="mb-3">Change Password</Card.Title>
              <Row className="align-items-end">
                <Form.Group as={Col} xs={6} className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="text"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password && touched.password}
                    value={values.password}
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={6} className="mb-3">
                  <Button
                    variant="outline-dark"
                    onClick={() =>
                      setFieldValue("password", generatePassword())
                    }
                  >
                    Generate password
                  </Button>
                </Form.Group>
              </Row>
              {!!errors.password && touched.password && (
                <div className="invalid-feedback d-block">
                  {errors.password}
                </div>
              )}
              {showRequirePasswordChange && (
                <Form.Check
                  className="mb-3"
                  inline
                  label="Require password change on next login"
                  name="require-password-change"
                  type="checkbox"
                  checked={requirePasswordReset}
                  onChange={() =>
                    setRequirePasswordReset(!requirePasswordReset)
                  }
                />
              )}
              <Button variant="outline-success d-block mb-3" type="submit">
                Change Password
              </Button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default PasswordReset;
