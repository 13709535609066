import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { LastUpdatedLabel } from "../../components";
import { PasswordReset, UserInformation } from "../../components/Accounts";
import AccountStatus from "../../components/Accounts/AccountStatus";
import LinkedReporters from "../../components/Accounts/LinkedReporters";
import { Account } from "../../models";
import { useAccountQuery } from "../queries";

function ReporterAccountDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { account, newAccount } = location.state as {
    account: Account;
    newAccount?: boolean;
  };

  const { data: reporterAccountData } = useAccountQuery(account.id);
  const [reporterAccount, setReporterAccount] = useState<Account>();

  useEffect(() => {
    if (!reporterAccountData) return;
    setReporterAccount(reporterAccountData);
  }, [reporterAccountData]);

  const handleCancel = () => {
    if (newAccount) {
      navigate("/reporter-accounts");
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <UserInformation accountData={reporterAccount} />

      <LinkedReporters reporterAccountData={reporterAccount} />

      <PasswordReset
        accountData={reporterAccount}
        setAccountData={setReporterAccount}
      />

      <AccountStatus
        accountId={account.id}
        accountData={reporterAccount}
        setAccountData={setReporterAccount}
      />

      <Row className="mb-5">
        <Col xs={6}>
          <Button variant="outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col xs={6}>
          <LastUpdatedLabel
            account={account.editor}
            timeStamp={account.updatedAt}
            label="Updated:"
          />
          <LastUpdatedLabel
            account={account.creator}
            timeStamp={account.createdAt}
            label="Created:"
          />
        </Col>
      </Row>
    </>
  );
}

export default ReporterAccountDetails;
