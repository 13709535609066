import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { Account, ReportingEntity } from "../../models";
import { AccountReportingEntities } from "../../models/AccountReportingEntities";
import {
  linkReportingEntity,
  reportingEntitySearch,
  unlinkReportingEntity
} from "../../pages/queries";
import ReportingEntityDetailsPopover from "../Overlays/ReportingEntityDetailsPopover";

type LinkedReporterProps = {
  reporterAccountData: Account | undefined;
  isReadOnly?: boolean;
};

function LinkedReporters({
  reporterAccountData,
  isReadOnly = false
}: LinkedReporterProps) {
  const [reporterAccount, setReporterAccount] = useState<Account>();
  const [accountReportingEntities, setAccountReportingEntities] = useState<
    AccountReportingEntities[]
  >([]);
  const [reportingEntitySearchResults, setReportingEntitySearchResults] =
    useState<ReportingEntity[]>([]);

  useEffect(() => {
    if (!reporterAccountData) return;
    setReporterAccount(reporterAccountData);
    setAccountReportingEntities(reporterAccountData.accountReportingEntities);
  }, [reporterAccountData]);

  const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.currentTarget;
    const searchTerm = target.searchTerm.value;

    if (searchTerm === "") return; //Stop if there is no search term
    const data = await reportingEntitySearch(searchTerm);
    setReportingEntitySearchResults(data);
  };

  const handleLinkReporter = async (reportingEntityId: number) => {
    const accountId = reporterAccount?.id;

    if (!reportingEntityId || !accountId) return; //Stop if parameters are not defined
    const linkedReportingEntitiesData = await linkReportingEntity(
      accountId,
      reportingEntityId
    );

    setAccountReportingEntities(linkedReportingEntitiesData);
  };

  const handleUnlinkReporter = async (reportingEntityId: number) => {
    const accountId = reporterAccount?.id;

    if (!reportingEntityId || !accountId) return; //Stop if parameters are not defined
    const linkedReportingEntitiesData = await unlinkReportingEntity(
      accountId,
      reportingEntityId
    );

    setAccountReportingEntities(linkedReportingEntitiesData);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Linked Reporters</Card.Title>
          <Card.Subtitle className="text-body-tertiary mb-3">
            Reporting entities linked to this account. You can view or unlink
            the reporting entities from this account.
          </Card.Subtitle>
          {reporterAccount && accountReportingEntities?.length > 0 ? (
            <Table responsive className="border">
              <thead>
                <tr>
                  <th className="bg-body-secondary">Name</th>
                  <th className="bg-body-secondary">Type</th>
                  <th className="bg-body-secondary">Category</th>
                  <th className="bg-body-secondary">License</th>
                  <th className="bg-body-secondary">Phone</th>
                  <th className="bg-body-secondary">Status</th>
                  {!isReadOnly && <th className="bg-body-secondary">Action</th>}
                </tr>
              </thead>
              <tbody>
                {accountReportingEntities.map(linkedReporter => {
                  if (!linkedReporter.reportingEntity) return <></>;
                  return (
                    <tr key={linkedReporter.reportingEntityId}>
                      <td>
                        <ReportingEntityDetailsPopover
                          reportingEntity={linkedReporter.reportingEntity}
                          value={linkedReporter.reportingEntity.name}
                        />
                      </td>
                      <td>
                        <Badge
                          pill
                          bg={`${
                            linkedReporter.reportingEntity.type?.id === 10
                              ? "yellow"
                              : "red"
                          }`}
                        >
                          {linkedReporter.reportingEntity.type?.name}
                        </Badge>
                      </td>
                      <td>{linkedReporter.reportingEntity?.category?.name}</td>
                      <td>{linkedReporter.reportingEntity.license}</td>
                      <td>{linkedReporter.reportingEntity.phoneNumber}</td>
                      <td>
                        <span
                          className={`status-dot status-dot-${
                            linkedReporter.reportingEntity.archivedAt
                              ? "archived"
                              : "active"
                          }`}
                        >
                          {linkedReporter.reportingEntity.archivedAt
                            ? "Archived"
                            : "Active"}
                        </span>
                      </td>
                      {!isReadOnly && (
                        <td>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() =>
                              handleUnlinkReporter(
                                linkedReporter.reportingEntityId
                              )
                            }
                          >
                            Unlink Reporter
                          </Button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p>
              <InfoCircle className="text-primary me-2" />
              There are no linked reporters.{" "}
              {!isReadOnly && "Link reporters from the search below."}
            </p>
          )}

          {!isReadOnly && (
            <Form onSubmit={handleSearch} className="mt-5">
              <Card.Title>Link Existing Reporter</Card.Title>
              <Card.Subtitle className="text-body-tertiary mb-3">
                You can search for a Reporter by name, phone, or license number.
                You link MANY reporters to this Reporter Account.
              </Card.Subtitle>
              <Row className="align-items-end mb-4">
                <Form.Group as={Col} sm={6} className="mb-3">
                  <Form.Label>Search by name, phone, or license</Form.Label>
                  <Form.Control type="text" name="searchTerm"></Form.Control>
                </Form.Group>
                <Col sm={6}>
                  <Button
                    variant="outline-primary"
                    className="mb-3 me-2"
                    type="submit"
                  >
                    Search for Reporter
                  </Button>
                  <Button
                    variant="outline-dark"
                    className="mb-3"
                    type="button"
                    onClick={() => setReportingEntitySearchResults([])}
                  >
                    Clear Results
                  </Button>
                </Col>
              </Row>
              {reportingEntitySearchResults.length > 0 && (
                <>
                  <div className="d-flex align-items-end mb-3">
                    <span className="lead me-3">
                      <strong>Search Results</strong>
                    </span>
                  </div>
                  <Table responsive className="border">
                    <thead>
                      <tr>
                        <th className="bg-body-secondary">Name</th>
                        <th className="bg-body-secondary">Type</th>
                        <th className="bg-body-secondary">Category</th>
                        <th className="bg-body-secondary">License</th>
                        <th className="bg-body-secondary">Phone</th>
                        <th className="bg-body-secondary">Status</th>
                        <th className="bg-body-secondary">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportingEntitySearchResults.map(reportingEntity => (
                        <tr key={reportingEntity.id}>
                          <td>
                            <ReportingEntityDetailsPopover
                              reportingEntity={reportingEntity}
                              value={reportingEntity.name}
                            />
                          </td>
                          <td>
                            <Badge
                              pill
                              bg={`${
                                reportingEntity.type?.id === 10
                                  ? "yellow"
                                  : "red"
                              }`}
                            >
                              {reportingEntity.type?.name}
                            </Badge>
                          </td>
                          <td>{reportingEntity?.category?.name}</td>
                          <td>{reportingEntity.license}</td>
                          <td>{reportingEntity.phoneNumber}</td>
                          <td>
                            <span
                              className={`status-dot status-dot-${
                                reportingEntity.archivedAt
                                  ? "archived"
                                  : "active"
                              }`}
                            >
                              {reportingEntity.archivedAt
                                ? "Archived"
                                : "Active"}
                            </span>
                          </td>
                          <td>
                            <Button
                              variant="outline-success"
                              size="sm"
                              onClick={() =>
                                handleLinkReporter(reportingEntity.id)
                              }
                            >
                              Link Reporter
                            </Button>
                          </td>
                        </tr>
                      ))}
                      <tr className="border-top"></tr>
                    </tbody>
                  </Table>
                </>
              )}
            </Form>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default LinkedReporters;
