import { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { LogItButtonRound, LogItPageHeader } from "../../components";
import { DebouncedInput } from "../../components/Tables/DebouncedInput";
import OrgAccountsTable from "../../components/Tables/OrgAccountsTable";
import Account from "../../models/Account";
import { isEmptyObj } from "../../utils";
import { useAccountsQuery } from "../queries";
import NewOrgAccount from "./NewOrgAccount";
import OrgAccountDetails from "./OrgAccountDetails";

function OrgAccounts({ orgAccountsData, setOrgAccountsData }: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [heading, setHeading] = useState("");
  const [headingDescription, setHeadingDescription] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const { data } = useAccountsQuery("all", {
    type: "Org"
  });

  const isBasePath = params["*"]?.length === 0 && isEmptyObj(orgAccountsData);

  // Set heading and descriptions
  useEffect(() => {
    if (isBasePath) {
      setHeading("Organization Accounts");
      setHeadingDescription("View all Organization Accounts.");
    } else if (params["*"] === "new") {
      setHeading("Add New Organization Account");
      setHeadingDescription(
        "Register a new Organization Account with a one-time password or email link"
      );
    } else if (params["*"] === "edit") {
      setHeading("Edit Organization Account");
      setHeadingDescription(
        "Update Organization Account user information, settings, and password"
      );
    } else if (!isBasePath) {
      navigate("/org-accounts");
    }
  }, [isBasePath, params, orgAccountsData, navigate]);

  const tableData: Account[] = useMemo(() => {
    return data?.map((account: Account) => {
      let updatedAt = null;

      if (account.updatedAt) {
        updatedAt = `${new Date(
          account.updatedAt
        ).toLocaleDateString()}, ${new Date(
          account.updatedAt
        ).toLocaleTimeString()}`;
      }
      return new Account({
        ...account,
        updatedAt
      });
    });
  }, [data]);

  const goToNewOrgAccountForm = () => {
    navigate("new");
  };

  const renderNewOrgButton = () => {
    return (
      <LogItButtonRound
        label="Add New Organization Account"
        onClick={goToNewOrgAccountForm}
        buttonStyle="filled"
      />
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-primary" onClick={() => navigate(-1)}>
        Cancel
      </Button>
    );
  };

  const renderTable = () => {
    return (
      <>
        <Card className="p-4">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={value => setGlobalFilter(String(value))}
          />
          <OrgAccountsTable
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Card>
      </>
    );
  };

  const handleEscape = () => {
    if (params["*"] === "new") {
      navigate("/org-accounts");
    }
    if (!isEmptyObj(orgAccountsData)) {
      setOrgAccountsData({});
    }
  };

  return (
    <div
      onKeyDown={e => {
        if (e.key === "Escape") {
          handleEscape();
        }
      }}
    >
      <LogItPageHeader
        heading={heading}
        description={headingDescription}
        renderButtons={isBasePath ? renderNewOrgButton : renderCancelButton}
      />

      <Routes>
        <Route path="/" element={renderTable()} />
        <Route path="edit" element={<OrgAccountDetails />} />
        <Route path="new" element={<NewOrgAccount />} />
      </Routes>
    </div>
  );
}

export default OrgAccounts;
