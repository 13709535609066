import { getNavigate } from "../providers/NavigationHelper";
import { AuthenticationService } from "../services";
import { AuthTokenResponse } from "../services/AuthenticationService";
const API_HOST = process.env.REACT_APP_API_HOST;
const REACT_APP_CLIENT_APPLICATION_ID =
  process.env.REACT_APP_CLIENT_APPLICATION_ID;

export type QueryParamOptions = {
  sort?: string;
  filter?: string;
  include?: string;
  fields?: string;
  id?: number;
  type?: string | string[];
  query?: string | Date;
  purchaseReportNumber?: number;
  offset?: number;
  limit?: number;
  start?: number;
  end?: number;
  searchTerm?: string;
};

/*
 * Client ID is used to determine which scheme to fetch data for
 * Access ID is used to authenticate the request
 */
const getHeaders = async () => {
  var aToken = window.localStorage.getItem("accessToken");
  var rToken = window.localStorage.getItem("refreshToken");

  if (aToken && rToken) {
    var response = (await AuthenticationService.refreshToken(
      aToken,
      rToken
    )) as AuthTokenResponse;
    if (response.accessToken && response.refreshToken) {
      window.localStorage.setItem("accessToken", response.accessToken);
      window.localStorage.setItem("refreshToken", response.refreshToken);
    } else {
      console.error("Error refreshing Token");
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      const navigate = getNavigate();
      navigate("/login");
      return;
    }
  }

  return {
    "Content-Type": "application/json",
    "X-Client-Id": `${REACT_APP_CLIENT_APPLICATION_ID}`,
    Authorization:
      "Bearer " + (window.localStorage.getItem("accessToken") || "")
  };
};

const processQueryParameters = (queryOptions?: QueryParamOptions) => {
  let queryParamsString = "";
  if (queryOptions) {
    const {
      sort,
      filter,
      include,
      fields,
      id,
      type,
      query,
      purchaseReportNumber,
      offset,
      limit,
      start,
      end,
      searchTerm
    } = queryOptions;
    if (sort) {
      queryParamsString = queryParamsString.concat(`&sort=${sort}`);
    }
    if (filter) {
      queryParamsString = queryParamsString.concat(`&filter=${filter}`);
    }
    if (include) {
      queryParamsString = queryParamsString.concat(`&include=${include}`);
    }
    if (fields) {
      queryParamsString = queryParamsString.concat(`&fields=${fields}`);
    }
    if (id) {
      queryParamsString = queryParamsString.concat(`&id=${id}`);
    }
    if (type) {
      queryParamsString = queryParamsString.concat(`&type=${type}`);
    }
    if (query) {
      queryParamsString = queryParamsString.concat(`&query=${query}`);
    }
    if (purchaseReportNumber) {
      queryParamsString = queryParamsString.concat(
        `&purchaseReportNumber=${purchaseReportNumber}`
      );
    }
    if (offset) {
      queryParamsString = queryParamsString.concat(`&offset=${offset}`);
    }
    if (limit) {
      queryParamsString = queryParamsString.concat(`&limit=${limit}`);
    }
    if (start) {
      queryParamsString = queryParamsString.concat(`&start=${start}`);
    }
    if (end) {
      queryParamsString = queryParamsString.concat(`&end=${end}`);
    }
    if (searchTerm) {
      queryParamsString = queryParamsString.concat(`&searchTerm=${searchTerm}`);
    }
    queryParamsString = `?${queryParamsString.slice(1)}`;
  }
  return queryParamsString;
};

export const getAccountId = (): number | null => {
  return Number(window.localStorage.getItem("accountId"));
};

class ApiService {
  async get(endpoint: string, queryParams?: QueryParamOptions) {
    const queryParamString = processQueryParameters(queryParams);
    const response = await fetch(`${API_HOST}/${endpoint}${queryParamString}`, {
      headers: await getHeaders()
    });
    if (!response.ok) {
      const error = await response.json();
      throw error;
    }
    return await response.json();
  }

  async post(endpoint: string, body: object) {
    const accountId = getAccountId();
    const response = await fetch(`${API_HOST}/${endpoint}`, {
      method: "POST",
      headers: await getHeaders(),
      body: JSON.stringify({ ...body, updatedBy: accountId })
    });

    // Check if the response status is OK
    if (!response.ok) {
      const error = await response.json();
      throw error;
    }

    // Check if there's content in the response (avoid parsing empty body)
    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Response is not JSON");
    }

    return await response.json();
  }

  async patch(endpoint: string, body: object) {
    const accountId = getAccountId();
    const response = await fetch(`${API_HOST}/${endpoint}`, {
      method: "PATCH",
      headers: await getHeaders(),
      body: JSON.stringify({ ...body, updatedBy: accountId })
    });

    if (!response.ok) {
      const error = await response.json();
      throw error;
    }

    // Check if there's content in the response (avoid parsing empty body)
    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      console.warn("Response is not JSON, returning response anyway");
      return response;
    }

    return await response.json();
  }

  async put(endpoint: string, body: object) {
    const response = await fetch(`${API_HOST}/${endpoint}`, {
      method: "PUT",
      headers: await getHeaders(),
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      const error = await response.json();
      throw error;
    }
    return await response.json();
  }

  async deleteCall(endpoint: string, body: object) {
    const response = await fetch(`${API_HOST}/${endpoint}`, {
      method: "DELETE",
      headers: await getHeaders(),
      body: JSON.stringify(body)
    });

    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const resBody = isJson && (await response.json());
    const error = resBody?.errors?.length ? resBody.errors[0] : null;
    if (error?.status === String(409) && error?.detail) {
      throw new Error(error.detail);
    }
    if (response.status > 204) {
      throw resBody;
    }
    return true;
  }
}

export default ApiService;
