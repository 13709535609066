import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Account } from "../../models";
import { updateAccountStatus, useDeleteAccount } from "../../pages/queries";

export type AccountSettingsProps = {
  accountId: number;
  accountData: Account | undefined;
  setAccountData?: React.Dispatch<React.SetStateAction<Account | undefined>>;
};

function AccountStatus({
  accountId,
  accountData,
  setAccountData
}: AccountSettingsProps) {
  const [account, setAccount] = useState<Account>();
  const [selectedAccountStatus, setSelectedAccountStatus] = useState(-1);
  const deleteReporterAccount = useDeleteAccount(accountId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!accountData) return;
    setAccount(accountData);
  }, [accountData]);

  const handleUpdateStatus = async () => {
    if (!account?.id) return;
    const response = await updateAccountStatus(
      account.id,
      selectedAccountStatus
    );
    if (response) {
      if (setAccountData) {
        setAccountData(response);
      }
      setSelectedAccountStatus(-1);
    }
  };

  const handleDeleteAccount = () => {
    if (!account?.id) return;
    if (
      window.confirm(
        "Are you sure you want to delete this account? This cannot be undone."
      )
    ) {
      deleteReporterAccount.mutate(
        //@ts-ignore
        {},
        {
          onSuccess: () => {
            setTimeout(() => {
              navigate("/reporter-accounts");
            }, 1500);
          }
        }
      );
    }
  };

  const getVariantColor = () => {
    const status = account?.status.name;
    if (!status) return;

    switch (status) {
      case "Active":
        return "success";
      case "Archived":
        return "danger";
      default:
        return "warning"; //could not find status value
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title className="mb-3">Account Status</Card.Title>
        <Row className="mb-3">
          <Col xs={6}>
            <Alert variant={getVariantColor()}>
              Account status:{" "}
              <strong>{account?.status.name || "Undefined"}</strong>
            </Alert>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Form.Group as={Col} xs={6} className="mb-3">
            <Form.Label className="me-3">Change Account Status</Form.Label>
            <Form.Select
              onChange={e =>
                setSelectedAccountStatus(parseInt(e.currentTarget.value))
              }
            >
              <option value={-1}>
                {account?.status.name || "Undefined"} (Current)
              </option>
              {account?.status.SourceAccountStatusTransition?.map(status => (
                <option
                  value={status.destAccountStatus.id}
                  key={status.destAccountStatus.id}
                >
                  {status.transitionVerb}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} xs={6} className="mb-3">
            {selectedAccountStatus !== -1 && (
              <Button onClick={handleUpdateStatus} variant="outline-success">
                Update Status
              </Button>
            )}
          </Form.Group>
        </Row>
        <div className="d-flex justify-content-end">
          <Button variant="outline-danger" onClick={handleDeleteAccount}>
            Delete Account
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default AccountStatus;
