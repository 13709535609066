import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { Account } from "../../models";
import { UpdateAccountRequestBody, updateAccount } from "../../pages/queries";

export type UserInformationProps = {
  accountData: Account | undefined;
  showAccountType?: boolean;
};

function UserInformation({
  accountData,
  showAccountType = false
}: UserInformationProps) {
  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    if (!accountData) return;
    setAccount(accountData);
  }, [accountData]);

  const userInformationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email Address is required")
  });

  const handleSaveAccount = async (values: { name: string; email: string }) => {
    // If account is undefined, do nothing
    if (!account) return;

    const updateAcct: UpdateAccountRequestBody = {
      id: account.id,
      loginId: account.loginId
    };

    if (values.name) updateAcct.name = values.name;
    if (values.email) updateAcct.email = values.email;

    await updateAccount(updateAcct);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>User Information</Card.Title>

        <Formik
          validationSchema={userInformationSchema}
          onSubmit={values => handleSaveAccount(values)}
          enableReinitialize
          initialValues={{
            name: account?.name ?? "",
            email: account?.email ?? ""
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={account?.loginId ?? ""}
                    disabled
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Row>
              {showAccountType && (
                <Row>
                  <Form.Group as={Col} md={6} className="mb-3">
                    <Form.Label>Account Type</Form.Label>
                    <Form.Control
                      type="text"
                      value={account?.accountType.name ?? ""}
                      disabled
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name && touched.name}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email && touched.email}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button variant="outline-success" className="me-3" type="submit">
                Save User Information
              </Button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default UserInformation;
