import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Form,
  FormControl,
  Row
} from "react-bootstrap";
import { CaretDownFill } from "react-bootstrap-icons";
import { AccountRole } from "../../models/Account";
import { useListRoles } from "../../pages/queries";

type AccountRoleControlProps = {
  options?: AccountRole[];
  isSelf?: boolean;
  handleAssignRole: (roleName: AccountRole) => Promise<any> | undefined | void;
  handleRemoveRole: (roleName: AccountRole) => Promise<any> | undefined | void;
};

function AccountRoleControl({
  options = [],
  isSelf,
  handleAssignRole,
  handleRemoveRole
}: AccountRoleControlProps) {
  // Role Section
  const { data: listRolesData } = useListRoles();
  const [roleOptions, setRoleOptions] = useState<AccountRole[]>([]);
  const [roleSearchText, setRoleSearchText] = useState<string>("");
  const [selectedRoleOptions, setSelectedRoleOptions] = useState<AccountRole[]>(
    []
  );
  const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState<boolean>(false);
  const [rolesDescriptionOpen, setRolesDescriptionOpen] = useState(false);

  useEffect(() => {
    if (!listRolesData) return;
    setRoleOptions(listRolesData);
  }, [listRolesData]);

  useEffect(() => {
    if (options.length === 0) return;
    setSelectedRoleOptions(options.map((x: any) => x.accountRole));
  }, [options]);

  // Filter options based on search input
  const filteredRoleOptions = roleOptions.filter(
    option =>
      option.name.toLowerCase().includes(roleSearchText.toLowerCase()) &&
      !selectedRoleOptions.some(
        selectedOption => selectedOption.id === option.id
      )
  );

  // Add an option to the selection
  const handleRoleSelect = async (option: AccountRole) => {
    try {
      await handleAssignRole(option);
      setSelectedRoleOptions(prevSelected => [...prevSelected, option]);
      setRoleSearchText("");
    } catch {
      //Do nothing, queries will handle toast error.
    }
  };

  // Remove an option from the selection
  const handleRoleRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: AccountRole
  ) => {
    e.stopPropagation();
    try {
      if (
        isSelf &&
        !window.confirm(
          "You are about to remove a role from your account, are you sure you want to continue?"
        )
      ) {
        return;
      }

      handleRemoveRole(option);
      setSelectedRoleOptions(prevSelected =>
        prevSelected.filter(item => item !== option)
      );
    } catch {
      //Do nothing, queries will handle toast error.
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title className="mb-3">Account Roles</Card.Title>
        <Row className="align-items-end">
          <Form.Group as={Col} xs={6} className="mb-3">
            <Form.Label className="me-3">Change Account Roles</Form.Label>
            <Dropdown
              className="roles-dropdown"
              show={isRoleDropdownOpen}
              onToggle={() => setIsRoleDropdownOpen(!isRoleDropdownOpen)}
            >
              <Dropdown.Toggle as="div">
                <div
                  className="d-flex align-items-center flex-wrap border border-secondary-subtle ps-1 pe-2 rounded"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsRoleDropdownOpen(!isRoleDropdownOpen)}
                >
                  {selectedRoleOptions.map(option => (
                    <Button
                      key={option.id}
                      size="sm"
                      variant="outline-primary"
                      className="me-1 my-1"
                      onClick={e => handleRoleRemove(e, option)} // Prevent closing the dropdown
                    >
                      {option.name}
                      <span className="ms-1">&times;</span>
                    </Button>
                  ))}

                  <FormControl
                    placeholder={
                      selectedRoleOptions.length === 0 ? "Search..." : ""
                    }
                    value={roleSearchText}
                    onChange={e => setRoleSearchText(e.target.value)}
                    className="w-auto flex-grow-1 me-1 border-0"
                    style={{
                      outline: "none",
                      boxShadow: "none"
                    }}
                  />
                  <CaretDownFill />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                {filteredRoleOptions.length > 0 ? (
                  filteredRoleOptions.map(option => (
                    <Dropdown.Item
                      key={option.id}
                      onClick={() => handleRoleSelect(option)}
                    >
                      {option.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No results found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group as={Col} xs={6} className="mb-3">
            <Button
              variant="outline-dark"
              onClick={() => setRolesDescriptionOpen(!rolesDescriptionOpen)}
              aria-controls="collapse-role-descriptions"
              aria-expanded={rolesDescriptionOpen}
            >
              {rolesDescriptionOpen ? "Hide" : "Show"} Role Description and
              Rules
            </Button>
          </Form.Group>
        </Row>
        <div>
          <Collapse in={rolesDescriptionOpen}>
            <div id="collapse-role-descriptions">
              <ul>
                {roleOptions.map(role => (
                  <li key={role.id}>
                    <strong>{role.name}</strong>: {role?.description}
                  </li>
                ))}
              </ul>
            </div>
          </Collapse>
        </div>
      </Card.Body>
    </Card>
  );
}

export default AccountRoleControl;
